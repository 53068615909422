import type { AggregatedAllResolvers } from '../../__codegen__/__graphql__/resolvers'
import { DEFAULT_COLLECTION_RANK } from '../constants'
import { convertToCollectionRanksConstants } from '../utils/converter'

export const AggregatedAll: AggregatedAllResolvers = {
  collectionRanks(parent) {
    // 디폴트 컬렉션 랭킹 순서를 갖기 보단, 추후 서버에서 컬렉션 랭킹을 기준으로 정렬된 오브젝트 배열을 받아 렌더링하자
    return parent.collectionRanks
      ? convertToCollectionRanksConstants(parent.collectionRanks)
      : DEFAULT_COLLECTION_RANK
  },
  shortcut(parent) {
    return {
      payload: parent.shortcut ?? null,
    }
  },
  advertisement(parent) {
    return {
      payload: parent.advertisement?.docs ?? [],
    }
  },
  job(parent) {
    return {
      payload: parent.job?.docs ?? [],
      hasNextPage: !!parent.job?.nextPageToken,
      isTargetHome: !!parent.job?.isHomeLanding,
    }
  },
  fleaMarket(parent) {
    return {
      payload: parent.fleamarket?.docs ?? [],
      hasNextPage: !!parent.fleamarket?.nextPageToken,
    }
  },
  catalogProductAdvertisement(parent) {
    return {
      payload: parent.catalogProductAd?.docs ?? [],
    }
  },
  community(parent) {
    return {
      payload: parent.community?.docs ?? [],
      hasNextPage: !!parent.community?.nextPageToken,
    }
  },
  businessPost(parent) {
    return {
      payload: parent.businessPost?.docs ?? [],
      hasNextPage: !!parent.businessPost?.nextPageToken,
    }
  },
  place(parent) {
    return {
      payload: parent.place?.docs ?? [],
      hasNextPage: !!parent.place?.nextPageToken,
    }
  },
  commerce(parent) {
    return {
      payload: parent.commerce?.docs ?? [],
      hasNextPage: !!parent.commerce?.nextPageToken,
    }
  },
  car(parent) {
    return {
      payload: parent.car?.docs ?? [],
      hasNextPage: !!parent.car?.hasNext,
      isTargetHome: !!parent.car?.isHomeLanding,
      searchFilter: parent.car?.filter ?? null,
    }
  },
  realty(parent) {
    return {
      payload: parent.realty?.docs ?? [],
      hasNextPage: !!parent.realty?.hasNext,
      isTargetHome: !!parent.realty?.isHomeLanding,
    }
  },
  karrotGroup(parent) {
    return {
      payload: parent.group?.docs ?? [],
      hasNextPage: !!parent.group?.hasNext,
      isTargetHome: !!parent.group?.isHomeLanding,
    }
  },
}
