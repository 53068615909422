import type { SearchCommunityRecommendedLocalProfileResolvers } from '../../__codegen__/__graphql__/resolvers'

export const SearchCommunityRecommendedLocalProfile: SearchCommunityRecommendedLocalProfileResolvers =
  {
    localProfiles(parent) {
      return (
        parent.source?.localProfiles.map((localProfile) => {
          return {
            id: `CommunityRecommendedLocalProfile#${localProfile.id}`,
            _id: localProfile.id,
            name: localProfile.name,
            summary: localProfile.summary,
          }
        }) ?? []
      )
    },
  }
